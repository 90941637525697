<template>
  <div>
    <CCard>
      <CCardHeader>
        Register User
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="4" sm="12">
            <CInput
              placeholder="Email"
              autocomplete="email"
              prepend="Email"
              :value.sync="email"
            />
          </CCol>
          <CCol md="4" sm="12">
            <CSelect
              placeholder="Contact Info Type"
              :options="imTypeList"
              :value.sync='imType'
            >
              <template #prepend-content><CIcon name="cil-chat-bubble"/></template>
            </CSelect>
          </CCol>
          <CCol md="4" sm="12">
            <CInput
              placeholder="Contact Info"
              class="mb-4"
              :value.sync='imInfo'
            >
              <template #prepend-content><CIcon name="cil-comment-bubble"/></template>
            </CInput>
          </CCol>
          <CCol md="12" sm="12">
            <CButton
              color="primary"
              square
              @click="registerUser"
            >
              Register User
            </CButton>
            <br>
          </CCol>
          <CCol md="12" sm="12" v-if="msg !== null">
            <hr>
            <h5>{{ msg }}</h5>
            <h5 v-if="password !== null">Password: {{ password }}</h5>
          </CCol>

        </CRow>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        User List
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CDataTable
              hover
              small
              fixed
              table-filter
              sorter
              items-per-page-select
              :items-per-page="15"
              :fields="userListFields"
              :items="userListItem"
              :noItemsView="{ noItems: 'No Item~' }"
              pagination
            >
              <template #action="{item}">
                <td class="py-2">
                  <CButtonGroup>
                    <CButton
                      color="primary"
                      square
                      @click="$router.push('/admin/user/edit/'+item.id)"
                    >
                      Manage
                    </CButton>
                    <CButton
                      color="danger"
                      square
                      @click="deleteUser(item.id)"
                    >
                      Delete
                    </CButton>
                    <CButton
                      color="info"
                      square
                      @click="enterGodMode(item.id)"
                    >
                      SwitchToUser
                    </CButton>
                  </CButtonGroup>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
export default {
  name: 'List',
  data () {
    const userListFields = [
      {key: 'id', label: 'ID'},
      {key: 'email', label: 'Email'},
      {key: 'user_name', label: 'Username'},
      {key: 'port', label: 'Port'},
      {key: 'reg_date', label: 'RegisterDate'},
      {key: 'expire_in', label: 'ExpireTime'},
      {
        key: 'action',
        label: 'Action',
        sorter: false,
        filter: false,
        _style: 'width: 10%'
      },
    ]
    const imTypeList = [
      {label: 'Select Your IM Type'},
      {value: 1, label: 'Wechat'},
      {value: 2, label: 'Tencent QQ'},
      {value: 3, label: 'Facebook'},
      {value: 4, label: 'Telegram'},
    ]
    return {
      fixedToasts: 0,
      returnMsg: '',
      alertLevel: 'success',
      userListFields,
      userListItem:[],
      email: null,
      imTypeList,
      imType: null,
      imInfo: null,
      msg: null,
      password: null
    }
  },
  mounted: function () {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function () {
      this.$axios.get("admin/user/list", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.userListItem = response.data.userList;
          }
        });
    },
    enterGodMode: function (userId) {
      this.$axios.post("admin/user/god-mode", {
        user_id: userId
      })
        .then((response) => {
          if (response.data.code === 200) {
            this.alertLevel = 'success';
            this.returnMsg = response.data.msg;
            this.fixedToasts++;
            setTimeout( () => this.$router.push({ path: '/index'}), 1000);
          }
        });
    },
    registerUser: function () {
      this.$axios.post("admin/user/register", {
        email: this.email,
        im_type: this.imType,
        im_info: this.imInfo
      })
        .then((response) => {
          if (response.data.code === 200) {
            this.msg = response.data.msg;
            this.password = response.data.password;
            this.onLoadPage()
          } else if (typeof response.data.code !== 'undefined') {
            this.msg = response.data.msg;
          }
        });
    },
    deleteUser: function (userId) {
      this.$axios.delete("admin/user/delete/"+userId, {})
        .then((response) => {
          if (response.data.code === 200) {
            this.alertLevel = 'success';
            this.returnMsg = response.data.msg;
            this.fixedToasts++;
            this.onLoadPage()
          }
        });
    }
  }
}
</script>